.NavbarItems {
  background: linear-gradient(rgb(201, 205, 243), rgb(255, 255, 255));
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 1.2rem;
  flex-direction: column;
  padding: 10px;
}

/* .NavbarItems > * {
  flex: 1;
} */

.navbar-logo {
  color: rgb(0, 0, 0);
  cursor: pointer;
  flex: 1;
  font-size: 20px;
  padding-bottom: 10px;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  list-style: none;
  width: auto;
  align-content: center;
  justify-content: space-evenly;
  flex: 1;
  padding-top: 10px;
}

/* .nav-menu:before {
  content: "";
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
} */

.nav-links {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: relative;
}

.nav-links:after {
  /* background-color: #6d76f7; */
  position: absolute;
  content: "";
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgb(70, 68, 68);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-links:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
