.project-site {
  color: #333;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid black;
  padding: 2px 10px;
  transition: all 0.5s ease-out;
}
.project-github {
  color: #333;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid black;
  padding: 2px 10px;
  transition: all 0.5s ease-out;
}
.project-site:hover,
.project-site:focus {
  background-color: #333;
  color: #fff;
}
.project-github:hover,
.project-github:focus {
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .project-title {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .project-description {
    padding-bottom: 10px;
  }
  .project-stacks {
    padding-top: 10px;
  }
  .project-links {
    padding-top: 10px;
  }
  .project-github {
    margin-left: 10px;
  }
  .project-container {
    margin-bottom: 40px;
  }
  .project-picture {
    width: 100%;
    min-height: 233.67px;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .project-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "picture title"
      "picture description"
      "picture break"
      "picture stacks"
      "picture links";
    margin-top: 80px;
    grid-gap: 0 80px;
  }
  .project-title {
    grid-area: title;
  }
  .project-picture {
    grid-area: picture;
    width: 80%;
    justify-self: flex-end;
  }
  .project-break {
    grid-area: break;
  }

  .project-description {
    grid-area: description;
  }
  .project-stacks {
    grid-area: stacks;
  }

  .project-links {
  }
  .project-site {
    grid-area: site;
  }
  .project-github {
    grid-area: github;
    margin-left: 10px;
  }
}

@media (min-width: 1280px) {
  .project-picture {
    max-width: 500px;
  }
}
